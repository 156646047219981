<template>
  <div>
    <postselect></postselect>
  </div>
</template>
<script>
import postselect from "../components/postselect/index.vue";
import eventbus from "../util/eventbus";
export default {
  components: {
    postselect,
  },
  data(){
    return{
      time:true
    }
  },
  created() {
    eventbus.$on("config", (e) => {
      localStorage.setItem("station", e);
      if(this.time){
        this.time = false;
        this.$router.go(-1);
      }
    });
  },
  destroyed(){
    this.time = true;
  }
};
</script>
<style scoped>
</style>